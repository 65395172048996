import constants from './constants';
import * as T from './types';

const wrappers = constants.nodes;
const hiddenClass = constants.classes.hidden;

function hideAll() {
  for (const key in wrappers) {
    wrappers[key]?.node?.classList?.add?.(hiddenClass);
  }
}

function showNode(node: HTMLElement) {
  hideAll();
  node.classList.remove(hiddenClass);
}

function showErrorPage(options?: T.ShowErrorPagePropsType) {
  const titleObject = wrappers.error.childrens.title;
  const messageObject = wrappers.error.childrens.message;
  const buttonsObject = wrappers.error.childrens.buttons;

  titleObject.node.innerText = options?.title?.label || titleObject.label;
  messageObject.node.innerText = options?.message?.label || messageObject.label;

  const buttonsList = options?.buttons?.list || buttonsObject.list;
  const buttonsElements = buttonsList?.map?.((button) => {
    const buttonElement = document.createElement('button');
    buttonElement.innerText = button?.label;
    buttonElement.onclick = async () => {
      buttonElement.disabled = true;

      const loader = document.createElement('div');
      loader.classList.add('root-config-error-loader');

      buttonElement.innerText = '';
      buttonElement.appendChild(loader);

      await button?.onClick();
      buttonElement.disabled = false;
      loader.remove();
      buttonElement.innerText = button?.label;
    };

    return buttonElement;
  });

  // buttonsObject.node.replaceChildren(...buttonsElements);
  // The approach below is needed since replaceChildren doens't work on JWebview
  buttonsObject.node.childNodes.forEach((child) => {
    child.remove();
  });
  buttonsObject.node.append(...buttonsElements);

  const errorNode = wrappers.error.node;
  showNode(errorNode);
}

function showLoaderPage() {
  const loaderNode = wrappers.loader.node;
  showNode(loaderNode);
}

export default {
  hideAll,
  showErrorPage,
  showLoaderPage
};
