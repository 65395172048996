import { start } from 'single-spa';
import registerApplications from './closures/registerApplications';
import { interfaces, behavior } from '@jarvis/shell-commons';
import { registerPostOnboardingForcedPageReload } from './fixme/registerPostOnboardingForcedPageReload';
import logger from './interfaces/logger';
import initializeApplicationStatusListener from './closures/initializeApplicationStatusListener';
import setGlobalJarvisWebHTTPConfig from './closures/setGlobalJarvisWebHTTPConfig';
import shellDefaultDependencies from './assets/shell-default-dependencies.json';
import configureSplunkUsingManifest from './closures/configureSplunkUsingManifest';
import { startServiceWorkers } from './services/serviceWorker';

(async () => {
  startServiceWorkers();
  const manifest = (window as any).Shell?.manifest;

  configureSplunkUsingManifest(manifest);
  initializeApplicationStatusListener();
  setGlobalJarvisWebHTTPConfig({ cache: manifest?.services?.cache });

  if (!manifest?.portal?.removeVeneerCss) {
    import('@veneer/core/dist/css/veneer.css');
  }

  await interfaces._shell.init({
    manifest,
    defaultAssetReferences: shellDefaultDependencies?.imports,
    events: undefined
  });

  // FIXME: behavior to be CammelCase since it's a class.
  await behavior?.init?.(interfaces);

  // FIXME: remove this workaround when possible
  registerPostOnboardingForcedPageReload(
    manifest?.services?.onboarding?.userOnboardingPath,
    interfaces.v1.navigation
  );

  (window as any).Shell = { manifest, ...interfaces };
  logger.debug('Inject commons on window');
  await registerApplications({ manifest, interfaces });

  start({
    urlRerouteOnly: true
  });
})();
