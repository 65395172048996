import { addErrorHandler, getAppStatus, LOAD_ERROR } from 'single-spa';
import applicationStatusManager from '../../services/applicationStatusManager';

let running = false;
export default async function initializeApplicationStatusListener() {
  if (running) {
    return;
  }
  running = true;

  addErrorHandler((err) => {
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      try {
        const mfe = System.resolve(err.appOrParcelName);
        System.delete(mfe);
      } catch (err) {
        console.error(err);
      }
      applicationStatusManager.showErrorPage();
    }
  });

  window.addEventListener('single-spa:before-first-mount', () => {
    applicationStatusManager.hideAll();
  });
}
