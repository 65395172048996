type PostMessageType = typeof navigator.serviceWorker.controller.postMessage;

let createdWebManifestScript = false;
async function enablePWA() {
  if (createdWebManifestScript) return;

  createdWebManifestScript = true;
  const link = document.createElement('link');
  link.rel = 'manifest';
  link.href = '/webapp.webmanifest';
  document.head.appendChild(link);
}

let started = false;
async function registerServiceWorkers(
  manifest: Record<string, any>
): Promise<void> {
  if (started) return;
  started = true;

  if (!navigator?.serviceWorker?.register) {
    console.warn('Service worker is not supported on this browser');
    return;
  } else {
    try {
      if (manifest?.services?.serviceWorker?.pwa?.enable) {
        enablePWA();
      }

      const registration = await navigator?.serviceWorker?.register?.(
        '/serviceworker.js'
      );

      const postMessage: PostMessageType = (message, options) => {
        if (navigator?.serviceWorker?.controller?.postMessage) {
          navigator?.serviceWorker?.controller?.postMessage?.(message, options);
        } else if (registration?.active?.postMessage) {
          registration?.active?.postMessage?.(message, options);
        } else if (registration?.waiting?.postMessage) {
          registration?.waiting?.postMessage?.(message, options);
        } else if (registration?.installing?.postMessage) {
          registration?.installing?.postMessage?.(message, options);
        }
      };

      postMessage({ manifest });
    } catch (err) {
      console.error('Error registering Service Worker:', err);
    }
  }
}

async function unRegisterServiceWorkers() {
  const registrations = await navigator?.serviceWorker?.getRegistrations?.();

  const unregisterPromiseList = registrations?.map?.((registration) =>
    registration?.unregister?.()
  );

  if (
    Array.isArray(unregisterPromiseList) &&
    unregisterPromiseList?.length > 0
  ) {
    await Promise.all(unregisterPromiseList);
  }
}

export async function startServiceWorkers(): Promise<void> {
  try {
    const manifest: any = await new Promise((resolve) => {
      let timer = 0;
      let isPromiseResolved = false;

      const resolvePromise = () => {
        const isTimedOut = timer > 2000;
        if (
          !isPromiseResolved &&
          ((window as any)?.Shell?.manifest || isTimedOut)
        ) {
          resolve((window as any)?.Shell?.manifest);
          isPromiseResolved = true;
          return isPromiseResolved;
        }
        return isPromiseResolved;
      };

      if (!resolvePromise()) {
        const manifestIntervalCheck = setInterval(() => {
          timer += 200;
          if (resolvePromise()) {
            clearInterval(manifestIntervalCheck);
          }
        }, 200);
      }
    });

    if (!manifest) return;
    const manifestServiceWorker = manifest?.services?.serviceWorker;

    if (manifestServiceWorker?.enable) {
      await registerServiceWorkers(manifest);
    } else {
      await unRegisterServiceWorkers();
    }
  } catch (error) {
    console.error('Failed to launch service worker: ', error);
  }
}
