import { registerApplication } from 'single-spa';
import idleLogoutClosure from './idleLogoutClosure';
import { services, utils } from '@jarvis/shell-commons';
import * as T from './types';
import applicationStatusManager from '../../services/applicationStatusManager';
import shellDefaultDependencies from '../../assets/shell-default-dependencies.json';

let initialized = false;

export default async function registerApplications(
  initializerState: T.registerApplicationsPropsType
): Promise<void> {
  if (initialized) {
    throw new Error('registerApplications was already called');
  }
  initialized = true;

  const { manifest, interfaces } = initializerState;
  const { store, navigation } = interfaces?.v1 || {};
  const { login } = manifest?.services || {};
  const loginAssetReference =
    login?.assetReference || '@jarvis/react-shell-login';
  const isLoginEnable = login?.enable !== false;
  const haveLogin = isLoginEnable && !!loginAssetReference;
  const sessionData = await services.session.getSessionData();
  const isLoggedIn = sessionData?.isLoggedIn;
  const stack = utils.stackValidator(manifest?.portal?.stack);

  const tenantSelectorAssetReference =
    shellDefaultDependencies?.imports?.['@jarvis/react-shell-tenant-selector'];

  const getCurrentRoute = () =>
    utils.getCurrentRoute({
      routes: manifest?.navigation?.routes,
      navigation
    });

  const customProps = {
    mosaicProps: manifest,
    session: services.session,
    stack: stack,
    history: navigation,
    navigateFunction: navigation.push,
    getCurrentRoute,
    applicationStatusManager
  };

  document.title = manifest?.portal?.appName;

  store.setState({
    manifest,
    session: sessionData
  });

  function isLoginActive() {
    if (!haveLogin) return false;
    const loginPaths = ['/login', '/loggedin', '/loggedout'];

    const isLoginPath = loginPaths.some(
      (e) => navigation.createHref({ pathname: e }) === window.location.pathname
    );

    if (isLoginPath || !isLoggedIn) {
      idleLogoutClosure.stopIdleTimer();
    } else {
      idleLogoutClosure.startIdleTimer(manifest);
    }

    return isLoginPath;
  }

  if (haveLogin) {
    registerApplication({
      name: loginAssetReference,
      app: () => System.import(loginAssetReference) as any,
      activeWhen: () => isLoginActive(),
      customProps
    });
  }

  registerApplication({
    name: '@jarvis/shell-nav',
    app: () => System.import('@jarvis/shell-nav') as any,
    activeWhen: () => !isLoginActive(),
    customProps: {
      ...customProps,
      tenantSelector: tenantSelectorAssetReference
    }
  });
}
