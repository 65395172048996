import { services } from '@jarvis/shell-commons';
import { ManifestType } from '@jarvis/shell-commons/dist/types/manifest';
import logger from '../../interfaces/logger';

type idleManagerType = {
  isActive: boolean;
  time: number;
  listeners?: Record<string, boolean>;
  manifest?: ManifestType;
  timeout?: NodeJS.Timeout;
  idleTimeoutFunction(): void;
  handleEvents(method: 'addEventListener' | 'removeEventListener'): void;
  start(): void;
  stop(): void;
  restart(): void;
};

const idleManager: idleManagerType = {
  isActive: false,
  time: 0,
  handleEvents: function (method) {
    const { listeners, restart } = idleManager;
    if (typeof listeners === 'object') {
      for (const key in listeners) {
        if (listeners[key] === true) {
          document[method](key, restart);
        }
      }
    }
  },
  start: function () {
    const { time, handleEvents, idleTimeoutFunction } = idleManager;

    handleEvents('addEventListener');

    idleManager.timeout = setTimeout(idleTimeoutFunction, time);
  },
  stop: function () {
    const { handleEvents, timeout } = idleManager;

    handleEvents('removeEventListener');

    clearTimeout(timeout);
  },
  idleTimeoutFunction: function () {
    const { manifest, handleEvents } = idleManager;
    handleEvents('removeEventListener');

    services.session
      .clearSession(manifest?.portal?.stack)
      .catch((err) => logger.error(err));
  },
  restart: function () {
    const { stop, start } = idleManager;

    stop();
    start();
  }
};

function stopIdleTimer() {
  const { isActive, stop } = idleManager;
  if (isActive) {
    stop();
  }
}

function startIdleTimer(manifest) {
  const stack = manifest?.portal?.stack;
  const { listeners: manifestListeners, time: manifestTime } =
    manifest?.services?.idle || {};

  if (!idleManager.isActive && typeof stack === 'string' && manifestTime > 0) {
    idleManager.isActive = true;
    idleManager.manifest = manifest;
    idleManager.time = manifestTime;
    idleManager.listeners = manifestListeners;

    idleManager.start();
  }
}

export default {
  stopIdleTimer,
  startIdleTimer
};
