import { setupCache, ISetupCache } from 'axios-cache-adapter';
import * as T from './types';

let cacheSetup: ISetupCache;
let lastToken: string;

const pendingPromises: Map<string, Map<string, Promise<any>>> = new Map();
const excludedMethods: ('get' | 'delete' | 'patch' | 'post' | 'put')[] = [
  'delete',
  'patch',
  'post',
  'put'
];

function getPendingPromise(token: string, key: string) {
  const tokenMap = pendingPromises.get(token);

  return tokenMap?.get?.(key);
}

function setPendingPromise(token: string, key: string, value: Promise<any>) {
  let tokenMap = pendingPromises.get(token);

  if (!tokenMap) {
    tokenMap = new Map();
    pendingPromises.set(token, tokenMap);
  }

  tokenMap.set(key, value);
}

function deletePendingPromise(token: string, key: string) {
  const tokenMap = pendingPromises.get(token);

  return tokenMap?.delete?.(key);
}

const adapter: ISetupCache['adapter'] = async (config) => {
  const currentToken = config.headers.Authorization || 'no-token';
  const fullURL = `${config.baseURL || ''}${config.url || ''}`;

  const isValidMethod = !excludedMethods?.some?.(
    (method) =>
      config.method?.toLocaleLowerCase?.() === method?.toLocaleLowerCase?.()
  );

  const thisPromiseBeforeAwait = getPendingPromise(currentToken, fullURL);

  if (isValidMethod && thisPromiseBeforeAwait) {
    await thisPromiseBeforeAwait;
  } else {
    const thisPromiseRequest = cacheSetup.adapter({
      ...config,
      baseURL: undefined,
      url: fullURL
    });

    if (isValidMethod) {
      setPendingPromise(currentToken, fullURL, thisPromiseRequest);
      thisPromiseRequest.then(() => {
        const cachedPromiseRequest = getPendingPromise(currentToken, fullURL);

        if (thisPromiseRequest === cachedPromiseRequest) {
          deletePendingPromise(currentToken, fullURL);
        }
      });
    }
    return thisPromiseRequest;
  }
};

export default function setGlobalJarvisWebHTTPConfig(
  options?: T.SetGlobalJarvisWebHTTPConfigPropsType
) {
  if (!cacheSetup && options?.cache?.enable) {
    const excludedPaths = options?.cache?.exclude?.paths?.map(
      ({ path }) => new RegExp(path)
    );

    const defaultMaxAge = 10 * 1000;

    cacheSetup = setupCache({
      maxAge: options?.cache?.maxAge || defaultMaxAge,
      exclude: {
        methods: excludedMethods,
        paths: excludedPaths,
        // Do not exclude from cache if it have query params
        query: false
      },
      // store,
      debug: false,
      invalidate: async (cfg, req) => {
        const thisLastToken = lastToken;
        const currentToken = req.headers.Authorization;
        lastToken = currentToken;

        const isSameToken = currentToken === thisLastToken;
        const isGET = req.method?.toLocaleLowerCase() === 'get';
        const thisIgnoreCache = !isGET || !isSameToken;

        cfg.ignoreCache = cfg.ignoreCache || thisIgnoreCache;
      }
    });
    (window as any).cacheSetup = cacheSetup;

    (window as any).jarvisWebHTTPConfig = {
      ...(window as any)?.jarvisWebHTTPConfig,
      defaultAdapter: adapter
    };
  }
}
